import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class PromptUpdateService {

  constructor(updates: SwUpdate) {
    console.log("Creating PromptUpdateService");
    updates.available.subscribe(event => {
      if (confirm("A new version of CallToAr.ms is available. Reload now?")) {
        updates.activateUpdate().then(() => document.location.reload());
      }
    });
  }
}
