import { Component, EventEmitter, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../auth.service';

import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { ResendConfirmationComponent } from '../resend-confirmation/resend-confirmation.component';

@Component({
  templateUrl: './sign-in-form.component.html',
  //template: `This is in the static component! {{ name }}`
})
export class SignInFormComponent implements OnInit {
  private modalRef: NgbModalRef;
  signInForm: UntypedFormGroup;
  errorMessage: string;

  constructor(private activeModal: NgbActiveModal,
              @Inject(forwardRef(() => AuthService)) private authService: AuthService,
              private fb: UntypedFormBuilder,
              private modalService: NgbModal,
    ) { }

    get email(): string {
    return this.signInForm.get('email').value;
  }

  get password(): string {
    return this.signInForm.get('password').value;
  }

  get rememberMe(): boolean {
    return this.signInForm.get('rememberMe').value;
  }

  ngOnInit(): void {
    this.signInForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      rememberMe: [false],
    });
  }

  signIn(): void {
    this.errorMessage = undefined;

    let result: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();

    this.authService.performSignIn(this.email, this.password, this.rememberMe, result);
    result.subscribe(res => {
      if (res) {
        this.errorMessage = res;
      } else {
        this.activeModal?.close();
      }
    });
  }

  showForgotPassword(): boolean {
    this.modalRef?.close();
    this.modalRef = this.modalService.open(ForgotPasswordComponent, { size: 'sm' });

    return false;
  }

  showConfirmModal(): boolean {
    this.modalRef?.close();
    this.modalRef = this.modalService.open(ResendConfirmationComponent);

    return false;
  }

  showUnlockModal(): boolean {
    alert("Coming soon. Contact support in the interim.");
    return false;
  }
}
