import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FormatModule } from '../shared/format/format.module';
import { LayoutModule } from '../shared/layout/layout.module';
import { NotificationIconComponent } from './notification-icon/notification-icon.component';
import { NotificationListComponent } from './notification-list/notification-list.component';

const routes: Routes = [
  { path: '', component: NotificationListComponent },
];

@NgModule({
  declarations: [
    NotificationIconComponent,
    NotificationListComponent,
  ],
  exports: [
    NotificationIconComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormatModule,
    LayoutModule,
    RouterModule.forChild(routes),
  ]
})
export class NotificationsModule { }
