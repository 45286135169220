import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../auth.service';
import { TitleService } from '../../shared/title.service';
import { ToastService } from '../../shared/toast.service';

import { Toast } from '../../types';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  loaded: boolean = false;
  confirmationCode: string = undefined;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public authService: AuthService,
    public title: TitleService,
    public toast: ToastService,
  ) {
    this.title.setTitle('Confirm Your Account');
    this.route.queryParamMap.subscribe(params => {
      this.confirmationCode = params.get('confirmation_token');
      this.loaded = true;

      this.authService.performAccountConfirm(this.confirmationCode).subscribe(result => {
        if (result) {
          this.toast.show(<Toast>{
            header: "You're all ready to go!",
            body: "Thanks for confirming your email address. You're all ready to get started!",
            theme: "success",
          });
          this.router.navigate(['/', 'dashboard']);
        } else {
          this.toast.show(<Toast>{
            header: "Uh oh",
            body: "Something went wrong. Is your confirmation token still valid?",
            theme: "danger",
          });
        }

      }, error => {
        this.toast.show(<Toast>{
          header: "Uh oh",
          body: "Something went wrong. Is your confirmation token still valid?",
          theme: "danger",
        });
      });
    });
  }

  ngOnInit(): void {
  }

}
