import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cta-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent {
  @Input('for')
  forInput: string = null;

  @Input()
  inputType: string;

  @Input()
  required: boolean = false;

  constructor() { }

}
