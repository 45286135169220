import { Component, OnInit } from '@angular/core';

import { NotificationService } from '../notification.service';
import { TitleService } from '../../shared/title.service';

@Component({
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

  constructor(private service: NotificationService,
    private title: TitleService,
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Your Notifications');
  }

}
