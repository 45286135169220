import { Component, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR }  from '@angular/forms';

const CHECKBOX_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true,
}


@Component({
  selector: 'cta-checkbox',
  templateUrl: './checkbox-input.component.html',
  providers: [CHECKBOX_ACCESSOR],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input()
  disabled: boolean;
  onChange = (_: any) => {};
  onTouched = () => {};

  @Input()
  value: string = "1";

  @Input()
  checked: boolean = false;

  @Input()
  id: string = "";

  @Input()
  readonly: boolean = false;

  @Input()
  required: boolean = false;

  @HostListener('change', ['$event.target.checked']) emitValue(checked: boolean) {
    this.checked = checked;
    this.onChange(this.checked);
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: boolean) {
    this.checked = obj;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
