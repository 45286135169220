import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DropdownItemComponent } from './dropdown-item/dropdown-item.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavItemComponent } from './navitem/navitem.component';

import { AuthModule } from '../../auth/auth.module';
import { LayoutModule } from '../layout/layout.module';
import { NotificationsModule } from '../../notifications/notifications.module';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    FontAwesomeModule,
    LayoutModule,
    NgbModule,
    NotificationsModule,
    RouterModule,
  ],
  declarations: [
    DropdownItemComponent,
    NavbarComponent,
    NavItemComponent,
  ],
  exports: [
    DropdownItemComponent,
    NavbarComponent,
    NavItemComponent,
  ],
  providers: [],
})
export class NavigationModule { }
