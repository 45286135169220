<nav class="navbar navbar-expand-md fixed-top navbar-dark bg-dark">
  <div class="container-fluid">
    <a routerLink="/" class="navbar-brand" (click)="isMenuCollapsed = true">CallToAr.ms</a>
    <button class="navbar-toggler"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle Navigation"
        data-target="#navbarCollapse"
        data-toggle="collapse"
        type="button"
        (click)="isMenuCollapsed = !isMenuCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbarCollapse" class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
      <ul ngbNav [roles]="false" class="me-auto navbar-nav">
        <ng-container *ngIf="user">
        <li #gamesDropdown ngbDropdown class="nav-item">
          <a href (click)="false" class="nav-link" ngbDropdownToggle>
            <fa-icon [icon]="faGamepadAlt" [fixedWidth]="true" class="me-1"></fa-icon>Games
          </a>
          <div ngbDropdownMenu class="dropdown-menu">
            <cta-dropdownitem link="/gamelibrary" (click)="isMenuCollapsed = true">Your Library</cta-dropdownitem>
            <cta-dropdownitem link="/games" (click)="isMenuCollapsed = true">Browse</cta-dropdownitem>
          </div>
        </li>
        <cta-navitem link="/events" [icon]="faCalendarAlt" (click)="isMenuCollapsed = true">Events</cta-navitem>
        <cta-navitem link="/friends" [icon]="faUserFriends" (click)="isMenuCollapsed = true">Friends
          <span *ngIf="notifications?.pendingFriendRequests > 0" class="badge text-bg-primary">
            {{ notifications.pendingFriendRequests }}
          </span>
        </cta-navitem>
        <cta-navitem link="/communities" [icon]="faUsersCrown" (click)="isMenuCollapsed = true">Communities</cta-navitem>
        </ng-container>
      </ul>
      <ul ngbNav [roles]="false" class="navbar-nav">
        <li *ngIf="!user?.hasPremium" ngbNavItem>
          <a class="nav-link" href="https://www.patreon.com/calltoarms" target="_blank">
            <fa-icon [icon]="faPatreon" [fixedWidth]="true" class="me-1"></fa-icon>
            Become A Patreon
          </a>
        </li>
        <cta-navitem link="/support" [icon]="faQuestionCircle" (click)="isMenuCollapsed = true">Help</cta-navitem>
        <cta-notification-icon *ngIf="isSignedIn()"></cta-notification-icon>
        <li *ngIf="isSignedIn()" #userDropdown ngbDropdown class="nav-item" placement="bottom-right">
          <a href (click)="false" class="nav-link" ngbDropdownToggle>
            <cta-user-avatar *ngIf="user" [user]="user" class="menu-avatar" size="1.25em"></cta-user-avatar>
            {{ user?.nickname }}
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
            <cta-dropdownitem *ngIf="user" [link]="'/u/' + user.slug" (click)="isMenuCollapsed = true">Your Profile</cta-dropdownitem>
            <div *ngIf="user" class="dropdown-divider"></div>
            <cta-dropdownitem link="/users/edit" [icon]="faUserCog" (click)="isMenuCollapsed = true">Settings</cta-dropdownitem>
            <cta-dropdownitem *ngIf="!user?.hasPremium" link="/users/edit/subscriptions" [icon]="upgradeIcon" (click)="isMenuCollapsed = true">Upgrade to Titan Class</cta-dropdownitem>
            <cta-dropdownitem link="" [icon]="faSignOut" (click)="performSignOut()" (click)="isMenuCollapsed = true">Log Off</cta-dropdownitem>
          </div>
        </li>
        <ng-container *ngIf="!isSignedIn()">
          <cta-navitem link="" [icon]="faSignIn" (click)="openSignInForm()" (click)="isMenuCollapsed = true">Log In</cta-navitem>
          <button (click)="authService.showSignUpForm()" class="btn btn-primary">Get Started</button>
        </ng-container>
      </ul>
    </div>
  </div>
</nav>
