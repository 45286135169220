import { Component, Input, OnInit, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'cta-navitem',
  templateUrl: './navitem.component.html',
  styleUrls: [
    './navitem.component.scss',
  ],
  //template: `This is in the static component! {{ name }}`
})
export class NavItemComponent implements OnInit {
  @Input()
  link: string;

  @Input()
  icon: IconProp | undefined;

  constructor() { }

  ngOnInit() {
  }
}
