import { Component, ContentChild, forwardRef, Input, Optional, Host, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'cta-text-input',
  providers: [ ],
  templateUrl: './text-input.component.html'
})
export class TextInputComponent implements ControlValueAccessor {
  disabled: boolean;
  private onChange: Function;
  private onTouched: Function;

  value: string = "";

  @Input()
  id: string = "";

  @Input()
  name: string = "";

  @Input()
  label: string = "";

  @Input()
  placeholder: string = "";

  @Input()
  readonly: boolean = false;

  @Input()
  required: boolean = false;

  @Input("type")
  inputType: string = "text";

  @Input()
  step: number;

  @Input("help-block")
  helpBlock: string = "";

  @ContentChild('prepend')
  prepend: TemplateRef<{}>;

  constructor(@Host() public control: NgControl) {
    this.control && (this.control.valueAccessor = this);
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    this.disabled = false;
  }

  writeValue(obj: string): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  valueChanged(event: any) {
    this.value = event.target.value;
    this.onChange(this.value);
    this.onTouched();
    this.control.control.setErrors({
      serverErrors: null
    });
    this.control.control.updateValueAndValidity();
  }

  get isInputGroup(): boolean {
    return this.prepend != null;
  }

  get serverErrors(): string[] {
    return this.control.getError('serverError');
  }

  get dirty(): boolean {
    return this.control.dirty;
  }
}
