import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simpleFormat',
})
export class SimpleFormatPipe implements PipeTransform {
  constructor() { }

  transform(input: string): string[] {
    if (!input) return [];

    return input.trim().replace(/[\r\n]{2,}/g, "\n").split("\n");
  }
}
