<div class="modal-header">
  <h5 class="modal-title">Report Content</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>
<form [formGroup]="form" (submit)="submitForm()">
  <div class="modal-body">
    <div class="form-group">
      <h5>What's wrong with this?</h5>
      <div class="form-check">
        <input id="radioReasonSpam" class="form-check-input" type="radio" value="spam" formControlName="reason">
        <label class="form-check-label" for="radioReasonSpam">This content is <strong>spam</strong></label>
      </div>
      <div class="form-check">
        <input id="radioReasonAbuse" class="form-check-input" type="radio" value="abuse" formControlName="reason">
        <label class="form-check-label" for="radioReasonAbuse">This content is <strong>abusive</strong> or contains <strong>hate speech</strong></label>
      </div>
      <div class="form-check">
        <input id="radioReasonSelfHarm" class="form-check-input" type="radio" value="self-harm" formControlName="reason">
        <label class="form-check-label" for="radioReasonSelfHarm">This content promotes <strong>self-harm</strong> or <strong>suicide</strong></label>
      </div>
      <div class="form-check">
        <input id="radioReasonOther" class="form-check-input" type="radio" value="other" formControlName="reason">
        <label class="form-check-label" for="radioReasonOther">Some other reason</label>
      </div>
      </div>
    <div class="form-group">
      <h5>Any other info you can provide?</h5>
      <textarea class="form-control" rows="3" formControlName="details"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" type="submit" [disabled]="form.invalid || loading" [ngSwitch]="loading">
      <fa-icon *ngSwitchCase="true" [icon]="spinner" [pulse]="true"></fa-icon>
      <ng-container *ngSwitchDefault>Report</ng-container>
    </button>
    <button class="btn btn-secondary" type="button" (click)="modal.dismiss()">Cancel</button>
  </div>
</form>
