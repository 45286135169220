import { Pipe, type PipeTransform } from '@angular/core'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { UserService } from '../../users/user.service'

dayjs.extend(advancedFormat)
dayjs.extend(timezone)
dayjs.extend(utc)

@Pipe({
  name: 'localizedTime'
})
export class LocalizedTimePipe implements PipeTransform {
  constructor (private readonly userService: UserService) {
  }

  transform (time: Date | string, format?: string): string {
    format = format || 'MMMM Do, YYYY [at] h:mmA'

    return dayjs(time).tz(this.userService.getTimeZone()).format(format)
  }
}
