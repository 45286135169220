import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SimpleFormatPipe } from './simple-format.pipe';
import { LocalizedTimePipe } from './localized-time.pipe';
import { RelativeTimePipe } from './relative-time.pipe';

@NgModule({
  declarations: [
    SimpleFormatPipe,
    LocalizedTimePipe,
    RelativeTimePipe,
  ],
  exports: [
    SimpleFormatPipe,
    LocalizedTimePipe,
    RelativeTimePipe,
  ],
  imports: [
    CommonModule
  ],
  providers: [
    SimpleFormatPipe,
    LocalizedTimePipe,
    RelativeTimePipe,
  ],
})
export class FormatModule { }
