import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from './auth.service';

/**
 * Enforces that a user is logged in before accessing the specified route
 */
@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = this.authService.signedIn();
    if (isLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/'], {
        queryParams: {
          returnUrl: state.url
        }
      });
      return false;
    }
  }
}

/**
 * Enforces that a user is NOT logged in before accessing the specified route
 */
@Injectable()
export class NotAuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = this.authService.signedIn();
    if (isLoggedIn) {
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      return true;
    }
  }
}
