<div class="d-inline-block" ngbDropdown (openChange)="handleOpenEvent($event)" #notificationDropdown="ngbDropdown">
  <button id="notification-button" class="btn btn-outline-dark text-center rounded nav-link" ngbDropdownToggle>
    <fa-icon [icon]="icon" [fixedWidth]="true"></fa-icon>
    {{ unreadNotificationCount }}
  </button>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end py-0 bg-secondary text-light" aria-labelledby="notification-button">
    <div class="d-flex flex-column">
      <div *ngIf="!hasNotifications" class="no-notifications">
        <fa-icon [icon]="noNotificationIcon" size="3x"></fa-icon>
        <p>Nice and clean! No notifications here!</p>
      </div>
      <div *ngIf="hasNotifications" class="notification-scroller">
        <div class="w-100 notification-list">
          <a *ngFor="let n of notifications" [routerLink]="notificationPath(n.url)" class="notification-item" [class.unread]="!n.readAt" (click)="notificationDropdown.close()">
            <div>
              <div class="icon">
                <img [src]="n.owner.avatarUrl" class="rounded-circle">
              </div>
              <div class="body"> {{ n.message }}</div>
              <div class="time small">{{ n.createdAt | relativeTime : "en-short" : true | async }}</div>
            </div>
          </a>
        </div>
      </div>
      <div class="text-center small viewall">
        <p><a routerLink="/notifications" class="text-light">View all notifications</a></p>
      </div>
    </div>
  </div>
</div>
