import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faCalendarAlt, faChevronDoubleUp, faGamepadAlt, faQuestionCircle, faSignIn, faSignOut, faUserCog, faUserFriends, faUsersCrown }
  from '@fortawesome/pro-regular-svg-icons';
import { faPatreon } from '@fortawesome/free-brands-svg-icons';

import { AuthService } from '../../../auth/auth.service';
import { UserService } from '../../../users/user.service';

import { User, UserNotificationCounts } from '../../../types';

@Component({
  selector: 'cta-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: [
    'navbar.component.scss',
  ],
})
export class NavbarComponent implements OnDestroy, OnInit {
  faCalendarAlt = faCalendarAlt;
  faGamepadAlt = faGamepadAlt;
  faPatreon = faPatreon;
  faQuestionCircle = faQuestionCircle;
  faSignIn = faSignIn;
  faSignOut = faSignOut;
  faUserCog = faUserCog;
  faUserFriends = faUserFriends;
  faUsersCrown = faUsersCrown;
  upgradeIcon = faChevronDoubleUp;

  isMenuCollapsed: boolean = true;

  user: User = undefined;
  notifications: UserNotificationCounts;

  constructor(private authService: AuthService,
              private userService: UserService,
              private modalService: NgbModal) { }

  ngOnDestroy(): void {
    this.authService.hideSignInForm();
  }

  ngOnInit(): void {
    this.userService.getUserStatus().subscribe(userProfile => {
      this.user = userProfile?.user;
      this.notifications = userProfile?.notifications;
    });
  }

  openSignInForm() {
    this.authService.showSignInForm();
  }

  performSignOut(): void {
    this.authService.performSignOut();
  }

  isSignedIn(): boolean {
    return this.authService.signedIn();
  }
}
