import { Component, Input } from '@angular/core';

import { VersionService } from '../version.service';

@Component({
  selector: 'cta-main-container',
  templateUrl: './maincontainer.component.html',
})
export class MainContainerComponent {
  @Input()
  fullWidth: boolean = false;

  @Input()
  hasBackground: boolean = true;

  year: number = new Date().getFullYear();

  constructor(private versionService: VersionService) { }

  get clientVersion(): string {
    return this.versionService.getClientVersion();
  }

  get serverVersion(): string {
    return this.versionService.getServerVersion();
  }
}
