<div class="modal-header">
  <h5 class="modal-title">Resend Confirmation</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<form (submit)="postForm()" [formGroup]="form">
  <div class="modal-body">
    <div *ngIf="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
    <p>
      Didn't receive your confirmation email? No problem! Enter your email address below, and if
      you have an account we'll resend the confirmation email.
    </p>
    <p>
      <strong>Be sure to check your Junk or Spam folders!</strong>
    </p>
    <div class="form-group">
      <label for="email">Email address</label>
      <input type="email" class="form-control" id="email" aria-describedby="email" formControlName="email">
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Submit</button>
  </div>
</form>
