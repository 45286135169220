import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportApiService {

  constructor(
    private http: HttpClient,
  ) { }

  postReport(itemType: string, itemId: string | number, reason: string, description: string): Observable<Object> {
    let fd: FormData = new FormData();
    fd.append('report[item_type]', itemType);
    fd.append('report[item_id]', '' + itemId);
    fd.append('report[reason]', reason);
    fd.append('report[details]', description);

    return this.http.post(`/report/${itemType}/${itemId}.json`, fd);
  }
}
