import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormatModule } from '../format/format.module';

import { CheckboxComponent } from './checkbox-input.component';
import { DateTimeComponent } from './datetimepicker-input/datetimepicker-input.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FormGroupComponent } from './form-group.component';
import { SelectInputComponent } from './select-input.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { TextInputComponent } from './text-input.component';
import { TimeZoneInputComponent } from './time-zone-input/time-zone-input.component';
import { LabelComponent } from './label/label.component';
// import { RadioInputComponent } from './radio-input/radio-input.component';

@NgModule({
  declarations: [
    CheckboxComponent,
    DateTimeComponent,
    FileUploadComponent,
    FormGroupComponent,
    SelectInputComponent,
    TextareaInputComponent,
    TextInputComponent,
    TimeZoneInputComponent,
    LabelComponent,
    //RadioInputComponent,
  ],
  exports: [
    CommonModule,
    DateTimeComponent,
    FontAwesomeModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,

    CheckboxComponent,
    FileUploadComponent,
    FormGroupComponent,
    LabelComponent,
    SelectInputComponent,
    TextareaInputComponent,
    TextInputComponent,
    TimeZoneInputComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormatModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
  ],
  providers: [],
})
export class FormModule { }

