import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Router, Routes } from '@angular/router';

import { AuthGuardService, NotAuthGuardService } from './auth/auth-guard.service';

import { environment } from '../environments/environment';

import { authRoutes } from './auth/auth.module';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [NotAuthGuardService],
    loadChildren: () =>
      import('./static/static.module').then(m => m.StaticModule)
  },
  {
    path: 'auth',
    children: authRoutes,
  },
  {
    path: 'communities',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./community/community.module').then(m => m.CommunityModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'events',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'friends',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./friends/friends.module').then(m => m.FriendsModule)
  },
  {
    path: 'gamelibrary',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./game-library/game-library.module').then(m => m.GameLibraryModule)
  },
  {
    path: 'games',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./games/games.module').then(m => m.GamesModule)
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import ('./notifications/notifications.module').then(m => m.NotificationsModule)
  },
  {
    path: 'support',
    loadChildren: () =>
      import ('./support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'u',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./users/user-profiles.module').then(m => m.UserProfilesModule)
  },
  {
    path: 'users',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./users/user.module').then(m => m.UserModule)
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./error/error.module').then(m => m.ErrorModule)
  },
];

const config: ExtraOptions = {
  enableTracing: !environment.production,
  //preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, config),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      this.router.navigate(['/', 'error', 404], {
        skipLocationChange: true,
        replaceUrl: false
      });
    }
  }
}
