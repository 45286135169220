import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../auth.service';
import { TitleService } from '../../shared/title.service';
import { ToastService } from '../../shared/toast.service';

import { Toast } from '../../types';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  token: string = undefined;
  validToken: boolean = undefined;

  form: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private title: TitleService,
    private toastService: ToastService,
  ) {
    this.form = this.fb.group({
      password: ['', [Validators.required]],
      passwordConfirmation: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Reset Your Password');
    this.route.queryParams.subscribe(params => {
      this.token = params['reset_password_token'];
      this.validToken = true;
    });
  }

  submitResetPassword(): void {
    this.authService.postResetPassword(
      this.token,
      this.form.get('password').value,
      this.form.get('passwordConfirmation').value,
    ).subscribe(result => {
      this.toastService.show(<Toast>{
        theme: 'success',
        header: 'Password Updated',
        body: 'Your password has been updated!',
      });
      this.router.navigate(['/dashboard']);
      console.log(result);
    }, err => {
      console.error(err);
    });
  }
}
