<cta-navbar></cta-navbar>
<router-outlet></router-outlet>
<cta-toastholder></cta-toastholder>
<ngx-spinner
      bdColor="rgba(0, 0, 0, 0.5)"
      size="medium"
      color="#fff"
      type="ball-clip-rotate-multiple"
      [fullScreen]="true"
      template="<img src='/assets/icons/icon-grayscale.svg' class='logo-spin' style='height:120px;width:120px'>">
  <p style="color: white">Loading...</p>
</ngx-spinner>
<script *ngIf="production" async defer data-domain="calltoar.ms" src="https://plausible.io/js/plausible.js"></script>
