import { Injectable } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ReportComponent } from './report/report.component';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private modal: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private router: Router,
  ) {
    console.log('INIT REPORT SERVICE');
    router.events.subscribe(event => {
      if (event instanceof ActivationStart) {
        this.closeModal();
        console.log('Intercepted nav');
      }
    });
  }

  reportItem(itemType: string, itemId: string | number) {
    this.closeModal();
    this.modal = this.modalService.open(ReportComponent, {});
    let component: ReportComponent = <ReportComponent>this.modal.componentInstance;
    component.itemType = itemType;
    component.itemId = itemId;
    console.log(`Reporting ${itemType}-${itemId}!`);
  }

  closeModal(): void {
    this.modal?.close();
  }
}
