import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ReportComponent } from './report/report.component';
import { ReportService } from './report.service';
import { ReportApiService } from './report-api.service';

@NgModule({
  declarations: [ReportComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
  ],
  providers: [
    ReportApiService,
    ReportService,
  ]
})
export class ReportModule { }
