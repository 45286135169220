export const environment = {
  production: true,
  baseUrl: "https://api.calltoar.ms",
  socketUrl: "wss://api.calltoar.ms/cable",
  supportEmail: "support@calltoar.ms",
  hcaptcha: {
    siteKey: 'c6cfe8b8-b295-4450-acaa-e6727b841e39',
  },
  honeybadgerApiKey: 'hbp_3rae4zQp9dMnde4E7jjgJs2MBUc5NL1RDaVf',
  reportErrors: true,
};
