import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../auth.service';
import { ToastService } from '../../shared/toast.service';

import { Toast } from '../../types';

@Component({
  selector: 'app-resend-confirmation',
  templateUrl: './resend-confirmation.component.html',
  styleUrls: ['./resend-confirmation.component.scss']
})
export class ResendConfirmationComponent implements OnInit {
  errorMessage: string | undefined;
  form: UntypedFormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  postForm(): void {
    this.authService.performReconfirm(this.form.get('email').value).subscribe(result => {
      this.showSuccessToast();
      this.activeModal.close();
    }, err => {
      // Error is an HTTP error, with statuses and all that
      if (err.status === 404) {
        this.showInvalidEmailToast();
      } else {
        this.showUnknownErrorToast();
      }
      console.error(err);
    });
  }

  private showSuccessToast(): void {
    this.toastService.show(<Toast>{
      theme: 'success',
      header: "It's on its way!",
      body: "We've sent off a new confirmation email. It'll be there any moment now!",
    });
  }

  private showInvalidEmailToast(): void {
    this.toastService.show(<Toast>{
      theme: 'danger',
      header: 'You sure you got that right?',
      body: "We couldn't find that email address on file. Are you sure you typed it correctly? You may want to register instead.",
    });
  }

  private showUnknownErrorToast(): void {
    this.toastService.show(<Toast>{
      theme: 'danger',
      header: 'Uh oh! Something went wrong!',
      body: "Something went wrong! We're pretty sure it's our fault. Contact support, and we'll help you out!",
    });
  }
}
