import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../auth.service';
import { ToastService } from '../../shared/toast.service';

import { ResetPasswordResponse, Toast } from '../../types';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  errorMessage: string | undefined;
  forgotPasswordForm: UntypedFormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private fb: UntypedFormBuilder,
    @Inject(forwardRef(() => AuthService)) private authService: AuthService,
  ) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  postForgotPassword(): void {
    this.authService.postForgotPassword(this.forgotPasswordForm.get('email').value).subscribe(result => {
      this.toastService.show(<Toast>{
        theme: 'success',
        header: 'Success!',
        body: result.message,
      });
      this.activeModal.close();
    }, err => {
      console.error(err);
    });
  }

}
