<div class="modal-header">
  <h5 class="modal-title">Sign Up</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<form (ngSubmit)="register()" [formGroup]="registerForm">
  <div class="modal-body">
    <cta-text-input type="email" label="Email" formControlName="email" [required]="true"></cta-text-input>
    <cta-text-input label="Nickname" formControlName="nickname" [required]="true"></cta-text-input>
    <cta-text-input type="password" label="Password" formControlName="password" [required]="true"></cta-text-input>
    <cta-text-input type="password" label="Password Confirmation" formControlName="password_confirmation" [required]="true"></cta-text-input>
    <cta-time-zone-input label="Time Zone" [required]="true" formControlName="time_zone"></cta-time-zone-input>
    <cta-checkbox formControlName="accept_terms">
      I accept the
      <a [routerLink]="['/', 'support', 'terms']" target="_blank">Terms and Conditions</a>
    </cta-checkbox>
    <cta-checkbox formControlName="accept_privacy">
      I accept the
      <a [routerLink]="['/', 'support', 'privacy-policy']" target="_blank">Privacy Policy</a>
    </cta-checkbox>
    <ng-hcaptcha formControlName="captcha"></ng-hcaptcha>
    <ul *ngIf="captcha.dirty && captcha.invalid" class="alert alert-danger small">
      <li *ngFor="let error of captcha?.errors?.serverError">{{ error }}</li>
    </ul>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" [disabled]="!registerForm.valid">Register Now</button>
  </div>
</form>
