<div class="modal-body">
  <div *ngIf="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
  <form (submit)="signIn()" [formGroup]="signInForm">
    <div class="form-group">
      <label for="exampleInputEmail1">Email address</label>
      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="email">
      <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1">Password</label>
      <input type="password" class="form-control" id="exampleInputPassword1" formControlName="password">
    </div>
    <div class="form-group form-check">
      <input type="checkbox" class="form-check-input" id="rememberMe" formControlName="rememberMe">
      <label class="form-check-label" for="rememberMe">Remember Me</label>
    </div>
    <div class="d-grid gap-2">
      <button type="submit" class="btn btn-primary">Submit</button>
    </div>
  </form>
  <hr>
  <p class="small text-center">
    <a href="" (click)="showForgotPassword()">Forgot your password?</a>
    <br>
    <a href="" (click)="showConfirmModal()">Didn't receive confirmation instructions?</a>
    <br>
    <a href="" (click)="showUnlockModal()">Didn't receive unlock instructions?</a>
  </p>
</div>
