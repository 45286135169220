import { EventEmitter, Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlTree } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import * as Honeybadger from '@honeybadger-io/js';

import { AngularTokenService, UserData } from 'angular-token';

import { RegistrationResponse, ResetPasswordResponse, User, UserRegistration } from '../types';

import { SignInFormComponent } from './sign-in-form/sign-in-form.component';
import { SignUpComponent } from './sign-up/sign-up.component';

import { NotificationService } from '../notifications/notification.service';
import { UserService } from '../users/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private modalRef;

  constructor(private http: HttpClient,
    private router: Router,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private userService: UserService,
    private tokenService: AngularTokenService) {
  }

  signedIn(): boolean {
    return this.tokenService.userSignedIn();
  }

  performSignIn(email: string, password: string, rememberMe: boolean, emitter: EventEmitter<string | undefined>): void {
    let fd: FormData = new FormData();
    fd.append('email', email);
    fd.append('password', password);
    fd.append('remember_me', rememberMe.toString());

    this.http.post('/auth/sign_in', fd, {headers: {'X-NoAuthCheck': 'true'}, observe: 'response'}).subscribe(result => {
      this.userService.updateUserStatus();
      this.notificationService.connect();
      let url: UrlTree = this.router.parseUrl(this.router.url);
      if (url.queryParams['returnUrl'] != null && url.queryParams['returnUrl'].length > 0) {
        this.router.navigate([url.queryParams['returnUrl']]);
      } else {
        this.router.navigate(['/dashboard']);
      }
      emitter?.emit();
    }, error => {
      console.error(error);
      emitter?.emit(error?.error?.errors);
    });
  }

  performSignOut(sendRequest: boolean = true, originalUrl?: string): void {
    if (sendRequest) {
      this.http.delete('/auth/sign_out.json').subscribe(result => {
        this.tokenService.authData.next(null);
        this.tokenService.userType.next(null);
        this.tokenService.userData.next(null);

        ['accessToken', 'client', 'expiry', 'tokenType', 'uid'].forEach((key) => localStorage.removeItem(key));

        this.userService.clearUserStatus();
        this.redirectToLogon(originalUrl);
      }, error => {
        console.error(error);
      });
    } else {
      this.tokenService.authData.next(null);
      this.tokenService.userType.next(null);
      this.tokenService.userData.next(null);

      ['accessToken', 'client', 'expiry', 'tokenType', 'uid'].forEach((key) => localStorage.removeItem(key));

      this.userService.clearUserStatus();
      this.redirectToLogon(originalUrl);
    }

    this.notificationService.destroy();
  }

  private redirectToLogon(originalUrl?: string): void {
    if (!originalUrl) {
      this.router.navigate(['/']);
    } else {
      let url: UrlTree = this.router.parseUrl(originalUrl);

      if (url.root.children[PRIMARY_OUTLET] && !url.queryParamMap.has('returnUrl')) { // Leave original
        this.router.navigate(['/'], {
          queryParams: {
            returnUrl: originalUrl
          },
        });
      }
    }
  }

  performRegister(registration: UserRegistration): Observable<RegistrationResponse> {
    let fd: FormData = new FormData();
    fd.append('email', registration.email);
    fd.append('nickname', registration.nickname);
    fd.append('password', registration.password);
    fd.append('password_confirmation', registration.passwordConfirmation);
    fd.append('time_zone', registration.timeZone);
    fd.append('g-recaptcha-response', registration.captcha);

    return this.http.post<RegistrationResponse>('/auth.json', fd, {headers: {'X-NoAuthCheck': 'true'}});
  }

  performAccountConfirm(confirmationToken: string): Observable<boolean> {
    return new Observable<boolean>(subscriber => {
      this.http.get(`/auth/confirmation?confirmation_token=${confirmationToken}`).subscribe(result => {
        subscriber.next(true);
        subscriber.complete();
      }, error => {
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }

  hideSignInForm(): void {
    this.modalRef?.close();
  }

  showSignInForm(): void {
    this.modalRef?.close();
    this.modalRef = this.modalService.open(SignInFormComponent, { size: 'sm' });
  }

  showSignUpForm(): void {
    this.modalRef?.close();
    this.modalRef = this.modalService.open(SignUpComponent);
  }

  checkSignedIn(): Observable<boolean> {
    return new Observable<boolean>(subscriber => {
      this.userService.getUserStatus().subscribe(result => {
        this.setDebuggerContext();
        this.notificationService.connect();
        subscriber.next(true);
        subscriber.complete();
      }, error => {
        Honeybadger.resetContext();
        this.notificationService.destroy();
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }

  postForgotPassword(email: string): Observable<ResetPasswordResponse> {
    let fd: FormData = new FormData();
    fd.append('email', email);
    fd.append('redirect_url', '');
    return this.http.post<ResetPasswordResponse>(`/auth/password.json`, fd);
  }

  postResetPassword(token: string, password: string, passwordConfirmation: string): Observable<boolean> {
    const params: HttpParams = new HttpParams().set('reset_password_token', token);
    const fd: FormData = new FormData();
    fd.append('password', password);
    fd.append('password_confirmation', passwordConfirmation);

    return new Observable<boolean>(subscriber => {
      this.http.put('/auth/password.json', fd, { params: params }).subscribe(result => {
        this.userService.updateUserStatus();
        subscriber.next(true);
        subscriber.complete();
      }, err => {
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }

  performReconfirm(email: string): Observable<any> {
    let fd: FormData = new FormData();

    fd.append('email', email);
    fd.append('redirect_url', '');

    return this.http.post('/auth/confirmation.json', fd);
  }

  private setDebuggerContext(): void {
    if (this.tokenService?.currentAuthData?.uid) {
      Honeybadger.setContext({
        user_id: this.tokenService.currentAuthData.uid,
      });
    }
  }
}
