<div class="modal-body">
  <div *ngIf="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
  <form (submit)="postForgotPassword()" [formGroup]="forgotPasswordForm">
    <p>
      Forgot your password? No problem! Enter your email address below, and if you have an account
      you'll receive an email to start the reset process.
    </p>
    <div class="form-group">
      <label for="exampleInputEmail1">Email address</label>
      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="email">
    </div>
    <div class="d-grid gap-2">
      <button type="submit" class="btn btn-primary">Submit</button>
    </div>
  </form>
</div>
