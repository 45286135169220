import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Toast } from '../types';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toasts: Toast[] = [];

  constructor() { }

  show(toast: Toast): void {
    this.toasts.push(toast);
  }

  hide(toast: Toast): void {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  get activeToasts(): Toast[] {
    return this.toasts;
  }
}
