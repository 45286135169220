import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

const SEPARATOR: string = ' :: ';
const SUFFIX: string = 'CallToAr.ms';
const DEFAULT: string = 'CallToAr.ms :: Play Games With Your Friends';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(private title: Title) { }

  setTitle(newTitle: string | Array<string> | undefined) {
    if (Array.isArray(newTitle) && newTitle.length > 0) {
      this.title.setTitle([...newTitle, SUFFIX].join(SEPARATOR));
    } else if (newTitle !== undefined && newTitle.length > 0) {
      this.title.setTitle([newTitle, SUFFIX].join(SEPARATOR));
    } else {
      this.clearTitle();
    }
  }

  clearTitle(): void {
    this.title.setTitle(DEFAULT);
  }
}
