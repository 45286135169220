import { Pipe, PipeTransform } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

let shortEn = <ILocale>{
  name: 'en-short',
  relativeTime: {
    future: 'now',
    past: '%s',
    s: 'now',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dm',
    y: '1y',
    yy: '%dy',
  },
  formats: {}
};

dayjs.locale(shortEn, null, true);

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {

  transform(time: Date, locale?: string, autoUpdate?: boolean): string | Observable<string> {
    if (!locale) {
      locale = 'en';
    }

    if (autoUpdate) {
      return interval(60000).pipe(
        startWith(0),
        map<number, string>(() => {
          return dayjs(time).locale(locale).fromNow();
        })
      );
    } else {
      return dayjs(time).locale(locale).fromNow();
    }
  }

}
