import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckUpdateService } from './check-update.service';
import { LogUpdateService } from './log-update.service';
import { PromptUpdateService } from './prompt-update.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    CheckUpdateService,
    LogUpdateService,
    PromptUpdateService,
  ],
})
export class UpdateModule { }
