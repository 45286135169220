import { Component, OnInit } from '@angular/core';
import { ToastService } from '../toast.service';

import { Toast } from '../../types';

@Component({
  selector: 'cta-toastholder',
  template: `
  <ngb-toast *ngFor="let toast of activeToasts()" [header]="toast.header" [autohide]="true" [delay]="10000" (hide)="hideToast(toast)">{{ toast.body }}</ngb-toast>
  `,
})
export class ToastHolderComponent implements OnInit {
  constructor(private toastService: ToastService) { }

  ngOnInit() {
  }

  activeToasts(): Toast[] {
    return this.toastService.activeToasts;
  }

  hideToast(toast: Toast): void {
    this.toastService.hide(toast);
  }
}
