import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../auth.service';
import { ToastService } from '../../shared/toast.service';
import { Toast, UserRegistration } from '../../types';

dayjs.extend(timezone);

@Component({
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  registerForm: UntypedFormGroup;
  errors: object;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    @Inject(forwardRef(() => AuthService)) private authService: AuthService,
    private toastService: ToastService,
  ) {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      nickname: ['', [Validators.required]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required]],
      time_zone: [dayjs.tz.guess(), [Validators.required]],
      accept_terms: [false, [Validators.requiredTrue]],
      accept_privacy: [false, [Validators.requiredTrue]],
      captcha: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  register(): void {
    let registration: UserRegistration = <UserRegistration>{
      email: this.registerForm.get('email').value,
      nickname: this.registerForm.get('nickname').value,
      password: this.registerForm.get('password').value,
      passwordConfirmation: this.registerForm.get('password_confirmation').value,
      timeZone: this.registerForm.get('time_zone').value,
      acceptTerms: this.registerForm.get('accept_terms').value,
      acceptPrivacy: this.registerForm.get('accept_privacy').value,
      captcha: this.registerForm.get('captcha').value,
    };

    this.authService.performRegister(registration).subscribe(result => {
      this.toastService.show(<Toast>{
        header: "You're Almost Ready To Go",
        body: "Check your email! We've sent you a confirmation email. Once done, you'll be all ready to go!",
        theme: "success",
      });
      this.activeModal.dismiss('registration-success');
    }, error => {
      this.toastService.show(<Toast>{
        header: "Check Yo Self",
        body: "Looks like there some problems. Please review.",
        theme: "danger",
      });
      console.error(error);
      this.errors = error?.error?.errors;
      this.updateErrorsForInputs();
    });
  }

  get captcha(): AbstractControl {
    return this.registerForm.get('captcha');
  }

  private updateErrorsForInputs(): void {
    Object.keys(this.errors).forEach(key => {
      const formControl = this.registerForm.get(key);
      if (formControl) {
        formControl.setErrors({
          serverError: this.errors[key]
        });
      }
    });
  }
}
