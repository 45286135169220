import { Component, Input, OnInit, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'cta-dropdownitem',
  template: `
  <a ngbDropdownItem [routerLink]="link">
    <fa-icon *ngIf="icon" [icon]="icon" [fixedWidth]="true" class="me-1"></fa-icon>
    <ng-content></ng-content>
  </a>
  `,
})
export class DropdownItemComponent implements OnInit {
  @Input()
  link: string;

  @Input()
  icon: IconProp | undefined;

  constructor() { }

  ngOnInit() {
  }
}
