<div class="boolean form-check" [class.optional]="!required" [class.required]="required">
  <cta-label class="form-check-label" [required]="required">
    <input type="checkbox"
        class="form-check-input"
        [class.optional]="!required"
        [class.required]="required"
        [id]="id"
        [attr.disabled]="disabled ? '' : null"
        [attr.readonly]="readonly ? '' : null"
        [value]="value"
        (change)="onChange"
        (touch)="onTouched"
        [attr.checked]="checked ? '' : null">
    <ng-content></ng-content>
  </cta-label>
</div>
