<ng-template #inputBody>
  <input
      class="form-control"
      (change)="valueChanged($event)"
      [attr.disabled]="disabled ? '' : null"
      [attr.readonly]="readonly ? '' : null"
      [id]="id"
      [name]="name"
      [placeholder]="placeholder"
      [type]="inputType"
      [value]="value"
      [attr.step]="step ? step : null">
</ng-template>
<div class="form-group">
  <cta-label [for]="id" [required]="required" [inputType]="inputType">{{ label }}</cta-label>
  <div *ngIf="isInputGroup" class="input-group">
    <div *ngIf="prepend" class="input-group-prepend">
      <span class="input-group-text"><ng-container *ngTemplateOutlet="prepend"></ng-container></span>
    </div>
    <ng-container *ngTemplateOutlet="inputBody"></ng-container>
  </div>
  <ng-container *ngIf="!isInputGroup" [ngTemplateOutlet]="inputBody"></ng-container>
  <p *ngIf="helpBlock">{{ helpBlock }}</p>
  <ul *ngIf="control.dirty && control.invalid" class="alert alert-danger small">
    <li *ngIf="control?.errors?.required">This is required</li>
    <li *ngFor="let error of serverErrors">{{ error }}</li>
  </ul>
</div>
