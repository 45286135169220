<cta-main-container>
  <cta-page-title>Reset Your Password</cta-page-title>
  <ng-container *ngIf="validToken === true">
    <form [formGroup]="form" (submit)="submitResetPassword()">
      <cta-text-input type="password" label="New Password" formControlName="password"></cta-text-input>
      <cta-text-input type="password" label="Confirm Password" formControlName="passwordConfirmation"></cta-text-input>
      <button type="submit" class="btn btn-primary">Update Password</button>
    </form>
  </ng-container>
</cta-main-container>
