import { Injectable } from '@angular/core';

import packageInfo from '../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private serverVersion: string;

  constructor() { }

  getClientVersion(): string {
    return packageInfo.version;
  }

  getServerVersion(): string {
    return this.serverVersion;
  }

  setServerVersion(version: string): void {
    this.serverVersion = version;
  }
}
