import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { multicast, tap, share } from 'rxjs/operators';

import { Game, User, UserEdit, UserProfile, UserSetupStatus } from '../types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userProfile: UserProfile = undefined;

  private userStatus: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(this.userProfile);

  private userStatusSubscription: Subscription;

  constructor(private http: HttpClient) {
    console.debug('userService constructed');
  }

  getUser(userSlug: string): Observable<UserProfile> {
    return this.http.get<UserProfile>(`/u/${userSlug}.json`);
  }

  editUserProfile(): Observable<UserEdit> {
    return this.http.get<UserEdit>('/auth/edit.json');
  }

  updateUserProfile(profile: UserEdit): Observable<UserEdit> {
    const fd: FormData = new FormData();
    if (profile.nickname != null) {
      fd.append('nickname', profile.nickname);
    }
    if (profile.email != null) {
      fd.append('email', profile.email);
    }
    if (profile.avatarUrl != null) {
      fd.append('avatar', profile.avatarUrl);
    }
    if (profile.timeZone != null) {
      fd.append('time_zone', profile.timeZone);
    }
    if (profile.password != null) {
      fd.append('password', profile.password);
    }
    if (profile.passwordConfirmation != null) {
      fd.append('password_confirmation', profile.passwordConfirmation);
    }
    if (profile.currentPassword != null) {
      fd.append('current_password', profile.currentPassword);
    }

    return this.http.put<UserEdit>('/auth.json', fd);
  }

  getUserFriends(userSlug: string): Observable<User[]> {
    return this.http.get<User[]>(`/u/${userSlug}/friends.json`);
  }

  getUserGames(userSlug: string): Observable<Game[]> {
    return this.http.get<Game[]>(`/u/${userSlug}/games.json`);
  }

  getUserStatus(force?: boolean): BehaviorSubject<UserProfile> {
    if (this.userProfile === undefined || force) {
      console.debug('userProfile is NOT cached; retrieve from server');
      this.updateUserStatus();
    } else {
      console.debug('userProfile is cached; return the cached result');
      this.userStatus.next(this.userProfile);
    }

    return this.userStatus;
  }

  getTimeZone(): string {
    return this.userProfile?.user?.timeZone;
  }

  updateUserStatus(): void {
    if (this.userStatusSubscription != null) {
      return;
    }

    this.userStatusSubscription = this.http.get<UserProfile>('/users/setup.json', {
      headers: {
        'X-DoSetupCheck': 'true'
      }
    }).subscribe(result => {
      this.userProfile = result;
      this.userStatus.next(this.userProfile);
      this.userStatusSubscription = null;
    }, error => {
      this.userProfile = null;
      this.userStatus.next(this.userProfile);
      this.userStatusSubscription = null;
    });
  }

  clearUserStatus(): void {
    this.userProfile = undefined;
    this.userStatus.next(this.userProfile);
  }

  search(query: string): Observable<User[]> {
    const params = new HttpParams().set('q', query);
    return this.http.request<User[]>('GET', '/users.json', { responseType: 'json', params });
  }
}
