import { Component, ElementRef, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getTimeZones } from '@vvo/tzdb';

const TIME_ZONE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimeZoneInputComponent),
  multi: true,
}

@Component({
  selector: 'cta-time-zone-input',
  templateUrl: './time-zone-input.component.html',
  styleUrls: ['./time-zone-input.component.scss'],
  providers: [TIME_ZONE_ACCESSOR],
})
export class TimeZoneInputComponent implements ControlValueAccessor,  OnInit {
  private disabled: boolean;
  private onChange: Function;
  private onTouched: Function;

  timeZones = getTimeZones();

  @Input()
  id: string = "";

  @Input()
  label: string | undefined;

  @Input()
  required: boolean = false;

  @Input()
  name: string = "";

  @Input('help-block')
  helpBlock: string | undefined;

  value: string | null;
  options: any[] = getTimeZones();

  @HostListener('change', ['$event.target.value']) emitValue(value: string) {
    console.log(value);
    this.value = value;
    this.onChange(this.value);
    this.onTouched();
  }

  constructor(private host: ElementRef<HTMLInputElement>) {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    this.disabled = false;
  }

  ngOnInit(): void {
  }

  writeValue(obj: string): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  formGroupClass(): string {
    let classes: string[] = [
      'form-group',
      'time_zone',
      this.id
    ];
    classes.push(this.required ? 'required' : 'optional');

    return classes.join(' ');
  }
}
