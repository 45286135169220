import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../types';

const DEFAULT_AVATAR = 'https://www.gravatar.com/avatar/80178c4489d54f1af3b572b7a7e3aad4.jpg?d=mp&r=pg&s=75';

@Component({
  selector: 'cta-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {
  @Input()
  user: User;

  @Input()
  size: string;

  constructor() { }

  ngOnInit(): void {
  }

  avatarUrl(): string {
    return this.user?.avatarUrl || DEFAULT_AVATAR;
  }

}
