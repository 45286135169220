import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpHeaders, HttpRequest, HttpEvent, HttpResponse, HttpUserEvent }
  from '@angular/common/http';

import { NgxSpinnerService } from 'ngx-spinner';
import { iif, Observable, of, Subject } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {
  DELAY = 200;
  private requests: HttpRequest<any>[] = [];

  private loadingIndicator = new Subject<boolean>();
  private delayedLoadingIndicator: Observable<unknown>;


  constructor(
    private spinner: NgxSpinnerService,
  ) {
    this.delayedLoadingIndicator = this.loadingIndicator.pipe(
      switchMap((loading) =>
        iif(() => loading,
          of(loading).pipe(delay(this.DELAY)),
          of(loading),
        )
      )
    );

    this.delayedLoadingIndicator.subscribe((loading) => {
      if (loading) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('X-NoLoadCheck')) {
      console.error("GOT IT!");
      req = req.clone({
        headers: req.headers.delete('X-NoLoadCheck')
      });

      return next.handle(req);
    }

    this.requests.push(req);

    console.log(`Start Request ${req.url}; count = ${this.requests.length}`);

    return Observable.create(observer => {
      this.loadingIndicator.next(true);
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              observer.next(event);
            }
          },
          err => {
            observer.error(err);
          },
          () => {
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

  private removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    console.log(`End Request ${req.url}; count = ${this.requests.length}`);
    if (this.requests.length === 0) {
      this.loadingIndicator.next(false);
    }
  }
}

