import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxSpinnerModule } from 'ngx-spinner';

import { AppComponent } from './app.component';
import { TitleService } from './shared/title.service';
import { LoadingIndicatorInterceptor } from './shared/loading-indicator.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './shared/layout/layout.module';
import { NavigationModule } from './shared/navigation/navigation.module';
import { UpdateModule } from './shared/update/update.module';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';

import { environment } from '../environments/environment';

import packageInfo from '../../package.json';

import * as Honeybadger from '@honeybadger-io/js';

Honeybadger.configure({
  apiKey: environment.honeybadgerApiKey,
  environment: (environment.production ? 'production' : 'development'),
  revision: packageInfo.version,
  reportData: (environment.production || environment.reportErrors),
});

class HoneybadgerErrorHandler implements ErrorHandler {
  handleError(error) {
    Honeybadger.notify(error.originalError || error);

    throw error;
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    NavigationModule,
    NgxSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    UpdateModule,
  ],
  providers: [
    TitleService,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingIndicatorInterceptor, multi: true },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production }),
    },
    { provide: ErrorHandler, useClass: HoneybadgerErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
