import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatStepperModule } from '@angular/material/stepper';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { NgSelectModule } from '@ng-select/ng-select';

import { CardComponent } from './card.component';
import { CheckComponent } from './check.component';
import { CommentListComponent } from './comment-list/comment-list.component';
import { EventListComponent } from './event-list.component';
import { GameCardComponent } from './game-card.component';
import { GameListComponent } from './game-list/game-list.component';
import { MainContainerComponent } from './maincontainer.component';
import { PageTitleComponent } from './pagetitle.component';
import { RecentActivityComponent } from './recent-activity/recent-activity.component';
import { SearchBarComponent } from './search-bar.component';
import { ToastHolderComponent } from './toastholder.component';
import { UserPanelSmallComponent } from './user-panel-small/user-panel-small.component';

import { FormModule } from '../form/form.module';
import { FormatModule } from '../format/format.module';
import { ReportModule } from '../report/report.module';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';

@NgModule({
  declarations: [
    CardComponent,
    CheckComponent,
    CommentListComponent,
    ConfirmModalComponent,
    EventListComponent,
    GameCardComponent,
    GameListComponent,
    MainContainerComponent,
    PageTitleComponent,
    RecentActivityComponent,
    SearchBarComponent,
    ToastHolderComponent,
    UserAvatarComponent,
    UserPanelSmallComponent,
  ],
  exports: [
    CommonModule,
    FontAwesomeModule,
    FormModule,
    MatStepperModule,
    NgbModule,
    NgSelectModule,

    CardComponent,
    CheckComponent,
    CommentListComponent,
    EventListComponent,
    GameCardComponent,
    GameListComponent,
    MainContainerComponent,
    PageTitleComponent,
    RecentActivityComponent,
    SearchBarComponent,
    ToastHolderComponent,
    UserAvatarComponent,
    UserPanelSmallComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormatModule,
    FormModule,
    MatStepperModule,
    NgbModule,
    ReportModule,
    RouterModule,
  ],
  providers: [
  ],
})
export class LayoutModule { }
