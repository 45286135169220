import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faPlus, faSpinner } from '@fortawesome/pro-regular-svg-icons';

import { ReportApiService } from '../report-api.service';
import { ToastService } from '../../toast.service';

import { Toast } from '../../../types';

@Component({
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  spinner = faSpinner;
  form: UntypedFormGroup;

  itemType: string;
  itemId: string | number;

  loading: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public modal: NgbActiveModal,
    private service: ReportApiService,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      reason: ['', [Validators.required]],
      details: ['', []],
    });
  }

  submitForm(): void {
    this.loading = true;
    this.service.postReport(
      this.itemType,
      this.itemId,
      this.form.controls['reason'].value,
      this.form.controls['details'].value,
    ).subscribe(result => {
      this.modal.close();
      this.toast.show(<Toast>{
        header: 'Got it',
        body: "We've received your report. Thanks for keeping CallToAr.ms safe!",
        theme: 'success',
      });
    }, error => {
      this.toast.show(<Toast>{
        header: 'Fire in the engine room!',
        body: "Uh oh! Something went wrong! We've received the error and will look into it.",
        theme: 'danger',
      });
    }, () => {
      this.loading = false;
    });
  }
}
