import { Component, Input, OnInit } from '@angular/core';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { faInbox } from '@fortawesome/pro-light-svg-icons';

import { NotificationService } from '../notification.service';

import { Notification } from '../../types';

@Component({
  selector: 'cta-notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.scss']
})
export class NotificationIconComponent implements OnInit {
  icon = faBell;
  noNotificationIcon = faInbox;

  constructor(private service: NotificationService) { }

  ngOnInit(): void {
  }

  markRead(notification: Notification) {
    this.service.markRead(notification);
  }

  handleOpenEvent(data: boolean): void {
    if (!data) {
      this.service.markAllRead();
    }
  }

  notificationPath(url: string): string {
    return url.replace(/^.*\/\/[^\/]+/, '');
  }

  get notifications(): Notification[] {
    return this.service.notifications;
  }

  get unreadNotificationCount(): number {
    let count: number = (this.service.notifications?.filter(n => n.readAt == null) || []).length
    return count;
  }

  get hasNotifications(): boolean {
    return this.service.notifications?.length > 0;
  }

}
