import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AngularTokenModule } from 'angular-token';
import { NgHcaptchaModule } from 'ng-hcaptcha';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignInFormComponent } from './sign-in-form/sign-in-form.component';
import { SignUpComponent } from './sign-up/sign-up.component';

import { FormModule } from '../shared/form/form.module';
import { LayoutModule } from '../shared/layout/layout.module';

import { AuthService } from './auth.service';
import { AuthGuardService, NotAuthGuardService } from './auth-guard.service';
import { AuthInterceptor } from './auth.interceptor';

import { environment } from '../../environments/environment';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResendConfirmationComponent } from './resend-confirmation/resend-confirmation.component';

export const authRoutes: Routes = [
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    canActivate: [ NotAuthGuardService ],
  },
  {
    path: 'password/edit',
    component: ResetPasswordComponent,
    canActivate: [ NotAuthGuardService ],
  },
];

@NgModule({
    imports: [
        AngularTokenModule.forRoot({
            apiBase: environment.baseUrl,
        }),
        CommonModule,
        FormModule,
        LayoutModule,
        NgHcaptchaModule.forRoot({
            siteKey: environment.hcaptcha.siteKey
        }),
        ReactiveFormsModule,
        RouterModule.forChild(authRoutes),
    ],
    declarations: [
        ForgotPasswordComponent,
        ConfirmationComponent,
        SignInFormComponent,
        SignUpComponent,
        ResetPasswordComponent,
        ResendConfirmationComponent,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        AuthService,
        AuthGuardService,
        NotAuthGuardService,
    ]
})
export class AuthModule { }
