import { Injectable } from '@angular/core';
import * as ActionCable from '@rails/actioncable';

import { environment } from '../../environments/environment';

import { Notification, NotificationPayload } from '../types';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private consumer: any;
  notifications: Notification[] = [];

  constructor() { }

  connect() {
    if (!this.consumer?.connection?.isActive()) {
      this.doConnect();
    }
  }

  private doConnect() {
    let uid: string = localStorage.getItem('uid');
    let token: string = localStorage.getItem('accessToken');
    let client: string = localStorage.getItem('client');
    this.consumer = ActionCable.createConsumer(`${environment.socketUrl}?uid=${uid}&token=${token}&client=${client}`);

    console.log('Attempting to connect to notification channel');

    let that = this;

    this.consumer.subscriptions.create('NotificationChannel', {
      connected() {
        that.send('get_pending');
        console.log("Subscription is ready for use");
      },
      disconnected() {
        console.log("Service terminated by WB server");
      },
      received(data) {
        that.addItems(data as NotificationPayload);
        console.log("This is the data received: ", that.notifications);
      }
    })
  }

  destroy() {
    this.consumer.disconnect();
  }

  // TODO: Do we really need this anymore? We're marking all notifications as read in one shot
  markRead(notification: Notification) {
    this.send('read', {
      id: notification.id
    });
  }

  markAllRead(): void {
    this.notifications?.filter(n => n.readAt == null)?.forEach(n => {
      n.readAt = new Date();
      this.send('read', {
        id: n.id
      });
    });
  }

  send(action: string, data?: Object) {
    if (!data) {
      data = {};
    }
    data['action'] = action;

    this.consumer.send({
      identifier: JSON.stringify({
        channel: 'NotificationChannel',
      }),
      command: 'message',
      data: JSON.stringify(data),
    });
  }

  private addItems(notifications: NotificationPayload) {
    if (notifications.type == 'initial') {
      this.notifications = notifications.notifications.filter(n => n != null && n.url != null);
    } else {
      // TODO: Alert sound?
      this.notifications = [...notifications.notifications, ...this.notifications];
    }

  }
}
