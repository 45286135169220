import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';

import { AuthService } from './auth/auth.service';

import { CheckUpdateService } from './shared/update/check-update.service';
import { PromptUpdateService } from './shared/update/prompt-update.service';
import { LogUpdateService } from './shared/update/log-update.service';

import { environment } from '../environments/environment';

@Component({
  selector: 'cta-app',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private swUpdate: SwUpdate,
    private logUpdateService: LogUpdateService,
    private checkUpdateService: CheckUpdateService,
    private promptUpdateService: PromptUpdateService,
  ) { }

  ngOnInit(): void {
    this.authService.checkSignedIn().subscribe();

    if (false && this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('New version available. Reload?')) {
          window.location.reload();
        }
      });
    }
  }

  get production(): boolean {
    return environment.production;
  }
}
