import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor, HttpHandler, HttpHeaders, HttpRequest, HttpEvent, HttpResponse, HttpUserEvent }
  from '@angular/common/http';
import { Router } from '@angular/router';
import { concat, Observable, EMPTY, throwError } from 'rxjs';
import { filter, first, take, tap } from 'rxjs/operators';
import { AngularTokenService } from 'angular-token';

import { AuthService } from './auth.service';
import { UserService } from '../users/user.service';
import { VersionService } from '../shared/version.service';

import { environment } from '../../environments/environment';

const HEADERS = new HttpHeaders({
  'Access-Control-Request-Headers': 'Authorization',
});

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private tokenService: AngularTokenService,
    private userService: UserService,
    private router: Router,
    private versionService: VersionService,
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    let ok: string;

    const authData = this.tokenService.authData.value;
    const fullUrl = environment.baseUrl + req.url;

    const isSetupCheck: boolean = req.headers.has('X-DoSetupCheck');

    // If this is a /setup.json call, treat it one way
    // Elif this is an X-NoAuthCheck call, treat it another way
    // Otherwise, do this a final way

    if (!req.headers.has('X-NoAuthCheck') && authData && this.matchesApiPath(fullUrl)) {
      req = req.clone({
        url: fullUrl,
        headers: req.headers.append('Authorization', '')
                            .append('access-token', authData.accessToken)
                            .append('client', authData.client)
                            .append('expiry', authData.expiry)
                            .append('token-type', authData.tokenType)
                            .append('uid', authData.uid)
                            .delete('X-NoAuthCheck')
                            .delete('X-DoSetupCheck')
      });
    } else {
      req = req.clone({
        url: fullUrl
      });
    }
    
    let result: Observable<any> = next.handle(req).pipe(tap(
      res => this.handleResponse(res),
      err => this.handleResponse(err)
    ));

    if (!isSetupCheck && authData) {
      const userStatusCall = this.userService.getUserStatus().pipe(
        filter((value, i) => i > 0 || value !== undefined),
        take(1),
        tap(value => {
          if (value === null) throwError(() => {
            return new Error('User status was null, cancelling request');
          });
        }),
      );

      result = concat(userStatusCall, result);
    }

    console.info(`Interceptor::AuthCheck START [${req.url}]`);

    return result;
  }

  private matchesApiPath(fullUrl: string): boolean {
    return this.tokenService.tokenOptions.apiBase === null || (fullUrl && fullUrl.match(this.tokenService.tokenOptions.apiBase) !== null);
  }

  private handleResponse(res: HttpResponse<any> | HttpErrorResponse | HttpEvent<any>): void {
    if (res instanceof HttpResponse || res instanceof HttpErrorResponse) {
      console.info(`Interceptor::AuthCheck END [${res.url}]`);
      this.setServerVersion(res);

      if (this.matchesApiPath(res.url)) {
        if (res.status === 401) {
          this.authService.performSignOut(false, this.router.url);
        } else {
          this.tokenService.getAuthHeadersFromResponse(res);
        }
      }

      this.navigateToErrorPage(res.status);
    }
  }

  private navigateToErrorPage(status: number): void {
    switch (status) {
      case 403:
      case 404:
      case 500:
        this.router.navigate(['/', 'error', status], {
          skipLocationChange: true,
          replaceUrl: false,
        });
        break;
    }
  }

  private setServerVersion(res: HttpResponse<any> | HttpErrorResponse): void {
    this.versionService.setServerVersion(res.headers.get('X-CallToArms-Version'));
  }
};
